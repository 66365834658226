<template>
    <div class="vue-box">
        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>项目实施</el-breadcrumb-item>
                <el-breadcrumb-item>项目详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="c-panel" v-if="project.id!=-1">
            <el-row justify="center">
                <el-col :span="4">
                    <el-row>
                        <el-col>项目名称</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{project.name}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="4">
                    <el-row>
                        <el-col>项目编号：</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{project.code}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="4">
                    <el-row>
                        <el-col>项目地点：</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{project.address}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="4">
                    <el-row>
                        <el-col>项目交付日期：</el-col>
                        <el-col style="font-weight: bold; margin-top: 10px;">{{project.finishDate}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="8" style=" display: flex;flex-direction: row;justify-content: center;margin-top: 5px;">
                    <el-button type="primary" size="small" @click="goto(project)" plain>查看更多</el-button>
                </el-col>
            </el-row>
        </div>

        <div class="c-panel-l">
            <div class="banner">
                <div @click="detail(item)" v-for="item in muens" :key="item.name" class="banner-item">
                    <div><img :src="item.image"></div>
                    <div class="item-info">{{item.name}}</div>
                </div>
            </div>
        </div>

       <div class="vue-box">
        <div class="c-panel">
            <!-- 参数栏 -->
            <el-form :inline="true" size="mini">
                <el-form-item label="任务名称：">
                    <el-input v-model="p.name" placeholder="模糊查询"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <input-enum enumName="taskStateEnum" v-model="p.state"></input-enum>
                </el-form-item>
                <el-form-item style="min-width: 0px">
                    <el-button type="primary" icon="el-icon-search" @click="f5();">查询 </el-button>
                    <el-button type="primary" icon="el-icon-plus" @click="add">增加</el-button>
                </el-form-item>
            </el-form>
            <!-- <div class="c-title">数据列表</div> -->
            <el-table :data="dataList" size="mini">
                <el-table-column type="selection"></el-table-column>
                <el-table-column label="产品名称" prop="name"></el-table-column>
                <el-table-column label="项目id" prop="projectId"></el-table-column>
                <el-table-column label="研发负责人" prop="userId"></el-table-column>
                <el-table-column label="指派用户" prop="users"></el-table-column>
                <el-table-column label="类型" prop="categoryEnum"></el-table-column>
                <el-table-column label="计划完成时间" prop="planDate"></el-table-column>
                <el-table-column label="实际完成时间" prop="finishDate"></el-table-column>
                <el-table-column label="状态" prop="stateEnum"></el-table-column>
                <el-table-column prop="address" label="操作" width="220px">
                    <template slot-scope="s">
                        <el-button class="c-btn" type="success" icon="el-icon-view" @click="one(s.row)">明细</el-button>
                        
                        <el-button class="c-btn" type="primary" icon="el-icon-edit" @click="update(s.row)">修改</el-button>
                        <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="page-box">
                <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
                </el-pagination>
            </div>
        </div>

        <!-- 增改组件 -->
        <add-or-operation ref="add-or-operation"></add-or-operation>
    </div>
    
        <!-- 增改组件 -->
        <add-or-update ref="add-or-update"></add-or-update>
    
    </div>
</template>

<script>
import inputEnum from '../../sa-resources/com-view/input-enum.vue';
import addOrUpdate from '../story/add.vue';
import addOrOperation from '../implement/add.vue';

export default {
    components: { inputEnum, addOrUpdate, addOrOperation },
    data() {
        return {
            group: [],
            title: "项目实施",
            state: '',
            counts: [],
            p: {
                pageSize: 100,
                page: 1,
                state: ''
            },
            project: {},
            muens: [
                { name: '物料到场情况', url: 'purchase-list', image: require('@/static/img/wuliao.png'), id: 3 },
                { name: '研发情况', url: 'task-one', image: require('@/static/img/yf.png'), id: 2 },
                { name: '项目实施日志', url: 'operation-list', image: require('@/static/img/jihua.png'), id: 5 },
                { name: '施工质量检查', url: 'check-list', image: require('@/static/img/shigong.png'), id: 6 },
                { name: '交付后现场照片', url: 'deliver-list', image: require('@/static/img/gongchengliang.png'), id: 7 },
                { name: '业主验收单', url: 'deliver', image: require('@/static/img/yaoqiu.png'), id: 8 },
                { name: '意见反馈', url: 'feedback-list', image: require('@/static/img/xuqiu.png'), id: 9 }
            ],
            operations: [],
            dataCount: 0,
            dataList: []

        }
    },
    methods: {
        ok() {
            this.f5();
            console.log("查询", this.state)
        },
        f5() {
            this.group = [];
            this.sa.get("/purchase/groupByProjectId").then(res => {
                this.counts = res.data;
            })
            this.p.projectId = this.project.id;
            this.sa.put("/project/listPage", this.p).then(res => {
                let item = [];
                res.data.content.forEach((element, index) => {
                    item.push(element);
                    if ((index + 1) % 4 == 0) {
                        this.group.push(item);
                        item = [];
                    }
                });
                console.log(this.group);
            })

            this.sa.put("/implement/listPage", this.p).then(res => {
                this.dataList = res.data.content.map(item => {
                    return item;
                });
                this.dataCount = res.data.totalElements;
            });

        },
        view(row, type) {
            if (type == 'deliver') {
                if (this.deliverLogs.length > 0) {
                    row = this.deliverLogs[0];
                } else {
                    row = { projectId: this.project.id, name: this.project.name };
                }
            } else {
                if (!row.id) {
                    row.projectId = this.project.id;
                }
            }
            this.$refs['add-' + type].open(row)
        },

        detail(row, type) {
            if(row.url){
                if(row.url == "deliver"){
                    this.project.category = 1
                }else{
                    this.project.category = 0;
                }
                this.sa_admin.navigateTo(row.url, this.project);
            }
        },
        // 删除
        del: function(data) {
            this.sa.confirm(
                "是否删除，此操作不可撤销",
                function() {
                    this.sa.delete("/implement/delete/" + data.id).then(res => {
                        console.log(res);
                        this.sa.arrayDelete(this.dataList, data);
                        this.sa.ok(res.message);
                    });
                }.bind(this)
            );
        },
        //更新
        update(row) {
            row.project = this.project;
            this.$refs["add-or-operation"].open(row);
        },
        //添加
        add: function() {
            var row = {id: 0};
            row.project = this.project;
            this.$refs["add-or-operation"].open(row);
        },

        goto() {
            this.sa_admin.navigateTo("purchase-list", { id: -1, projectCode: '' });
        },
        one(row){
            row.project = this.project;
            this.sa_admin.navigateTo("operation-one", row)
        }
    },
    created() {
        this.project = this.sa_admin.params;
        this.title = this.sa_admin.nativeTab.name;
        this.f5();


    }
}
</script>

<style scoped>
.title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
}
.banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20px;
   
}

.row-header {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row-header .more {
    margin-top: 10px;
    margin-right: 100px;
}

.banner .banner-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 26.7%;
    height: 90px;
    margin-right: 20px;
    background-color: #ffffff;
}
.banner .banner-item img {
    width: 42px;
}

.header {
    margin: 20px;
}

.item-info {
    margin-left: 20px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 22px;
}
</style>